<template>
  <div>
    <ed-empresa :intCadastroGeralId="0" :intTipoCadastroGeralId="300" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdEmpresa from "@/components/cadastro/geral/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdEmpresa },
  mounted() {},
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>